<template>
  <div class="create-topSale">
    <div v-for="(subItem, subIndex) in visitInfo.topSaleList" :key="subIndex" class="topSale-item">
      <div class="item-title">{{ subItem.labelName }}</div>
      <div class="item-info">
        <!-- <img class="icon-img" src="@/assets/images/btn_side_add.png" alt=""> -->
        <van-uploader :disabled="visitInfo.isEdit == 0" v-model="subItem.imgUrl" :max-count="1" :after-read="(files, detail) => afterRead(files, detail, subItem)" />
        <div class="right-info">
          <van-row type="flex" gutter="5">
            <van-col span="8">Brand</van-col>
            <van-col span="8">Name</van-col>
            <van-col span="8">Sales per day</van-col>
          </van-row>
          <van-row type="flex" gutter="5">
            <van-col span="8"><van-field :disabled="visitInfo.isEdit == 0" v-model="subItem.brand" placeholder="" /></van-col>
            <van-col span="8"><van-field :disabled="visitInfo.isEdit == 0" v-model="subItem.saleName" placeholder="" /></van-col>
            <van-col span="8"><van-field :disabled="visitInfo.isEdit == 0" type="number" v-model="subItem.saleCount" placeholder="" /></van-col>
          </van-row>
          <van-row type="flex">
            <van-col span="24"><van-field :disabled="visitInfo.isEdit == 0" v-model="subItem.remark" placeholder="Note:" /></van-col>
          </van-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { commonFileUploadApi } from '@/api/home'
import CreateMixins from './createMixins'
export default {
  name: 'TopSale',
  mixins: [CreateMixins],
  props: {
    visitInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {}
  },
  // beforeDestroy() {
  //   console.log('beforeDestroy-topSale')
  //   let channelVisitCreateInfo = {}
  //   if (localStorage.getItem('channelVisitCreateInfo')) {
  //     channelVisitCreateInfo = JSON.parse(localStorage.getItem('channelVisitCreateInfo'))
  //   }
  //   channelVisitCreateInfo = {
  //     ...channelVisitCreateInfo,
  //     toSaleList: this.toSaleList
  //   }
  //   localStorage.setItem('channelVisitCreateInfo', JSON.stringify(channelVisitCreateInfo))
  // },
  methods: {
    afterRead(files, detail, params) {
      const form = new FormData()
      form.append('file', files.file)
      console.log('detail', detail)
      console.log('params', params)
      Toast.loading({
				message: 'loading...',
				forbidClick: true,
				duration: 0
			})
      commonFileUploadApi(form).then(res => {
        Toast.clear()
        console.log('commonFileUploadApi', res)
        params.mainUrl = `${res.data.domain}${res.data.path}`
        params.imgUrl[detail.index].url = `${res.data.domain}${res.data.path}`
      }).catch(() => {
        Toast.clear()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.create-topSale {
  font-size: 12px;
  .topSale-item {
    .item-title {
      padding: 10px;
      font-size: 14px;
      font-weight: bold;
    }
    .item-info {
      display: flex;
      padding: 10px;
      border-top: 1px solid #dce5f6;
      border-bottom: 1px solid #dce5f6;
      .right-info {
        flex: 1;
        margin-left: 10px;
      }
      /deep/.van-row {
        margin-bottom: 10px;
      }
      /deep/.van-cell {
        padding: 0;
        border: 1px solid #dce5f6;
        input {
          padding-left: 5px;
        }
      }
    }
  }
  .icon-img {
    width: 40px;
    height: 40px;
  }
}
</style>
